import { useEffect, useRef } from "react";

export const usePluginRun = (cb: Function) => {

    const isCalledRef = useRef(false);

    useEffect(() => {
        if (!isCalledRef.current) {
            isCalledRef.current = true;
            cb();
        }
    })


}