import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { usePluginRun } from "../hooks/usePluginRun"

declare const HSSideNav: any;

function Aside() {

    usePluginRun(() => {
        new HSSideNav('.js-navbar-vertical-aside').init() 
    })

    const location = useLocation()  
    
    return (
        <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered   ">
            <div className="navbar-vertical-container">
                <div className="navbar-vertical-footer-offset">

                    <a className="navbar-brand" href="../index.html" aria-label="Front">
                        <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Logo" data-hs-theme-appearance="default" />
                        <img className="navbar-brand-logo-mini" src="/assets/img/logo-circle-voices.png" alt="Logo" data-hs-theme-appearance="default" />
                    </a>

                    <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                        <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                        <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                    </button>
                    

                    <div className="navbar-vertical-content">
                        <div id="navbarVerticalMenu" className="nav nav-pills nav-vertical card-navbar-nav">


                            <span className="dropdown-header fw-bold">Operación</span>
                            <small className="bi-three-dots nav-subtitle-replacer"></small>

                            <div className="nav-item">
                                <Link className="nav-link " to='/'>
                                    <i className="bi-speedometer2 nav-icon"></i>
                                    <span className="nav-link-title">Dashboard</span>
                                </Link>
                            </div>

                            <div className="nav-item">
                                <Link className="nav-link dropdown-toggle collapsed" to='/' data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuReports">
                                    <i className="bi-grid-1x2 nav-icon"></i>
                                    <span className="nav-link-title">Reportes</span>
                                </Link>
                                {/* show */}
                                <div id="navbarVerticalMenuReports" className="nav-collapse collapse " data-bs-parent="#navbarVerticalMenu" hs-parent-area="#navbarVerticalMenu" >
                                    <Link className="nav-link " to='/'>Tickets</Link>
                                    <Link className="nav-link " to='/'>Pagos</Link>
                                    <Link className="nav-link " to='/'>Clientes</Link>
                                    <Link className="nav-link " to='/'>Actividad de agentes</Link>
                                    <Link className="nav-link " to='/'>SMS</Link>
                                    <Link className="nav-link " to='/'>Chatbot</Link>
                                </div>
                            </div>
                            <div className="nav-item ">
                                <Link className={`nav-link ` + (location.pathname.startsWith('/bd-clients') ? 'active': '')} to='/bd-clients'>
                                    <i className="bi-person-bounding-box nav-icon"></i>
                                    <span className="nav-link-title">Base de clientes</span>
                                </Link>
                            </div>
                            <div className="nav-item">
                                <Link className="nav-link " to='/'>
                                    <i className="bi-send-plus nav-icon"></i>
                                    <span className="nav-link-title">Envío masivo</span>
                                </Link>
                            </div>


                            <span className="dropdown-header fw-bold mt-5">plataforma</span>
                            <small className="bi-three-dots nav-subtitle-replacer"></small>


                            <div className="nav-item">
                                <Link className={`nav-link ` + (location.pathname.startsWith('/configuration') ? 'active': '')} to='/configuration'>
                                    <i className="bi-gear nav-icon"></i>
                                    <span className="nav-link-title">Configuración</span>
                                </Link>
                            </div>
                            <div className="nav-item">
                                <Link className={`nav-link ` + (location.pathname.startsWith('/channels') ? 'active': '')} to='/channels'>
                                    <i className="bi-collection nav-icon"></i>
                                    <span className="nav-link-title">Canales</span>
                                </Link>
                            </div>

                            

                            <div className="nav-item">
                                <Link className={`nav-link ` + (location.pathname.startsWith('/users') ? 'active': '')} to='/users'>
                                    <i className="bi-people nav-icon"></i>
                                    <span className="nav-link-title">Usuarios</span>
                                </Link>
                            </div>

                            
                            <div className="nav-item ">
                                <Link className={`nav-link ` + (location.pathname.startsWith('/payments') ? 'active': '')} to='/payments'>
                                    <i className="bi-credit-card-2-back nav-icon"></i>
                                    <span className="nav-link-title">Pagos</span>
                                </Link>
                            </div>
                            <div className="nav-item">
                                <Link className="nav-link " to='/bot/builder'>
                                    <i className="bi-robot nav-icon"></i>
                                    <span className="nav-link-title">Chatbot</span>
                                </Link>
                            </div>


                            {/* <span className="dropdown-header">Bots</span>
                            <small className="bi-three-dots nav-subtitle-replacer"></small>

                            <div className="nav-item">
                                <a className="nav-link " href="../documentation/typography.html" data-placement="left">
                                    <i className="bi-layers nav-icon"></i>
                                    <span className="nav-link-title">Ver</span>
                                </a>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Aside