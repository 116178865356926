import { useState } from "react";
import { useForm } from "react-hook-form";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import Input from "../../components/form-controls/Input";
import TextArea from "../../components/form-controls/TextArea";
import Header from "../../components/Header";
import IfThen from "../../components/IfThen";

export default function PaymentPage() {
    const defaultValues = {
        total: '',
        time: '',
        template: '',

    }
    const { register, handleSubmit, formState: { errors, isValid, isSubmitting, submitCount } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const [allowPayments, setAllowPayments] = useState(false)

    return (
        <>
            <Header />
            <Aside />

            <main id="content" role="main" className="main">

                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Pagos</li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title"><i className="bi-credit-card-2-back nav-icon"></i> Pagos</h1>
                            </div>
                            <IfThen condition={allowPayments}>
                                <div className="col-sm-auto">
                                    <button className="btn btn-danger" onClick={() => setAllowPayments(false)}><i className="bi-exclamation-circle me-1"></i> Desactivar ordenes de pago</button>
                                </div>
                            </IfThen>

                        </div>
                    </div>

                    <IfThen condition={!allowPayments}>
                        <div className="row justify-content-sm-center text-center py-10">
                            <div className="col-sm-7 col-md-5">
                                <img className="img-fluid mb-5" src="/assets/svg/illustrations/oc-add-card.svg" alt="Métodos de pagos" />

                                <h1>¡Métodos de pago inactivos!</h1>
                                <p>Para comenzar a crear ordenes de pago, primero debes de activar esta opción</p>

                                <button className="btn btn-primary" onClick={() => setAllowPayments(true)}>Permitir generar ordenes de pago</button>
                            </div>
                        </div>
                    </IfThen>

                    <IfThen condition={allowPayments}>
                        <div className="row">
                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <h4>Configuración general</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit consequatur exercitationem tenetur adipisci. </p>
                            </div>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-6">
                                                <Input label='Cantidad de ordenes activas por TICKET-AGENT *' errors={errors.total} {...register('total', { required: true })} />
                                            </div>
                                            <div className="col-sm-6">
                                                <Input label='Tiempo de vida de una orden de pago *' errors={errors.time} {...register('time', { required: true })} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <TextArea label='Plantilla de mensaje *' errors={errors.template} {...register('template', { required: true })} />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end gap-3 mt-5">
                                            <button type="button" className="btn btn-primary">Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-5" />

                        <div className="row">
                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <h4>Métodos de pago</h4>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit consequatur exercitationem tenetur adipisci. </p>
                            </div>
                            <div className="col-lg-8">
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <div  className="card">

                                            <div className="card-body">                                                
                                                    <div className="list-group list-group-lg list-group-flush list-group-no-gutters">

                                                        <div className="list-group-item">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 ms-3">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <img style={{ height: '25px' }} src="https://v3.ws-voices.com.mx/assets/img/openpay-logo.png" alt="Image Description" />
                                                                            <div className="mt-2">
                                                                                <span className="badge bg-soft-primary  text-primary">Método de pago activo</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="d-flex align-items-center">
                                                                                <button type="button" className="btn btn-white btn-sm "><i className="bi bi-gear-fill"></i> Configurar</button>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="list-group-item">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 ms-3">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <img style={{ height: '25px' }} src="https://v3.ws-voices.com.mx/assets/img/paypal-logo.png" alt="Image Description" />
                                                                            <div className="mt-2">
                                                                                <span className="badge bg-soft-danger text-danger">Método de pago inactivo</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="d-flex align-items-center">
                                                                                <button type="button" className="btn btn-white btn-sm "><i className="bi bi-gear-fill"></i> Configurar</button>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="list-group-item">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 ms-3">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <img style={{ height: '25px' }} src="https://v3.ws-voices.com.mx/assets/img/logo_conekta_color.svg" alt="Image Description" />
                                                                            <div className="mt-2">
                                                                                <span className="badge bg-soft-danger text-danger">Método de pago inactivo</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="d-flex align-items-center">
                                                                                <button type="button" className="btn btn-white btn-sm "><i className="bi bi-gear-fill"></i> Configurar</button>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-danger text-danger">Requiere configuración</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/openpay-logo.png' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Configurar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-danger text-danger">Método de pago inactivo</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/paypal-logo.png' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Administrar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-primary  text-primary">Método de pago predeterminado</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/logo_conekta_color.svg' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Administrar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                </div> */}
                            </div>
                        </div>
                    </IfThen>

                </div>
                <Footer />
            </main>


        </>
    )
}