import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Button from "../../components/form-controls/Button";
import Input from "../../components/form-controls/Input";

declare const HSCore: any;

function ResetPasswordPage() {

    const { register, handleSubmit, formState: { errors, isValid, isSubmitting, submitCount  } } = useForm({
        mode: 'onChange',
        defaultValues: {
            username: '',
        },
    });

    useEffect(() => {
        document.body.className = '';

        HSCore.components.HSTomSelect.init('.js-select')

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main pt-0">

                <div className="container-fluid px-0">

                    <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
                        <div className="d-none d-lg-flex justify-content-end">
                            <div className="tom-select-custom tom-select-custom-end tom-select-custom-bg-transparent zi-2">
                                <select className="js-select form-select form-select-sm form-select-borderless" data-hs-tom-select-options='{
                                    "searchInDropdown": false,
                                    "hideSearch": true,
                                    "dropdownWidth": "12rem",
                                    "placeholder": "Select language"
                                    }' defaultValue={'language5'}>
                                    <option label="empty"></option>
                                    <option value="language1"  data-option-template='<span class="d-flex align-items-center"><img class="avatar avatar-xss avatar-circle me-2" src="/assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="Image description" width="16"/><span>English (US)</span></span>'>English (US)</option>
                                    <option value="language5" data-option-template='<span class="d-flex align-items-center"><img class="avatar avatar-xss avatar-circle me-2" src="/assets/vendor/flag-icon-css/flags/1x1/es.svg" alt="Image description" width="16"/><span>Español</span></span>'>Español</option>
                                    <option value="language8" data-option-template='<span class="d-flex align-items-center"><img class="avatar avatar-xss avatar-circle me-2" src="/assets/vendor/flag-icon-css/flags/1x1/cn.svg" alt="Image description" width="16"/><span>中文 (繁體)</span></span>'>中文 (繁體)</option>
                                </select>
                            </div>

                        </div>
                    </div>

                    <div className="row mx-0">
                        <div
                            className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
                            style={{
                                backgroundImage: 'url(/assets/img/auth-bg.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'bottom'
                            }}
                        >
                        </div>


                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <form className="js-validate needs-validation" >
                                    <div className="mb-5">
                                        <Link to='/auth/login' className="btn btn-link text-decoration-underline fw-semibold px-0">
                                            <i className="bi-arrow-left"></i> Inicio de sesión
                                        </Link>
                                    </div>

                                    <div className="text-center">
                                        <div className="d-grid">
                                            <img className=" mb-5 logo-auth" src="/assets/img/logo.png" alt="Description" />
                                        </div>

                                        <div className="mb-5">
                                            <h1 className="display-5 text-start">Recuperar contraseña</h1>
                                            <p className="text-start">Ingresa tu nombre de usuario para enviarte a tu correo electrónico tu nueva contraseña</p>
                                        </div>
                                    </div>


                                    <div className="my-4">
                                        <Input label='Usuario' errors={errors.username} {...register('username', { required: true })} />
                                    </div>


                                    <div className="d-grid mb-3">
                                        <Button type="submit" className="btn btn-primary btn-lg" disabled={!isValid}  isLoading={isSubmitting}>Recuperar contraseña</Button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    );
}

export default ResetPasswordPage;