import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select'
import moment from 'moment';

declare const HSCore: any;
declare const $: any;

interface CustomDataTableProps {
    columns: Array<any>;
    data: Array<any>;
    filters?: Array<React.ReactNode>;
    showDates?: boolean;
    hideExport?: boolean;
}

function CustomDataTable( props: CustomDataTableProps) {


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    useEffect(() => {
        var start = moment().subtract(29, 'days');
        var end = moment();

        function cb(start, end) {
            $('#js-daterangepicker-predefined .js-daterangepicker-predefined-preview').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        $('#js-daterangepicker-predefined').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Hoy': [moment(), moment()],
                'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
                'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
                'Mes actual': [moment().startOf('month'), moment().endOf('month')],
                'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                customRangeLabel: 'Personalizado',
                daysOfWeek: [
                    "Do",
                    "Lu",
                    "Ma",
                    "Mi",
                    "Ju",
                    "Vi",
                    "Sa"
                ],
                monthNames: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ],
            }
        }, cb);

        cb(start, end);
    }, [])




    return (
        <div className="card">

            <div className="card-header card-header-content-md-between">

                <div className="mb-2 mb-md-0" >
                    <div className="input-group input-group-merge ">
                        <div className="input-group-prepend input-group-text">
                            <i className="bi-search"></i>
                        </div>
                        <input type="search" className="form-control" placeholder="Escribe para filtrar" />
                    </div>
                </div>

                <div className="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2">

                    {
                        props.showDates && (
                            <button id="js-daterangepicker-predefined" className="btn btn-white">
                                <i className="bi-calendar-week me-1"></i>
                                <span className="js-daterangepicker-predefined-preview"></span>
                            </button>
                        )
                    }
                    
                    <div className="dropdown">
                        <button type="button" className="btn btn-white btn-sm w-100" id="usersFilterDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <i className="bi-filter me-1"></i> Filtros <span className="badge bg-soft-dark text-dark rounded-circle ms-1">1</span>
                        </button>

                        <div className="dropdown-menu dropdown-menu-sm-end dropdown-card card-dropdown-filter-centered" aria-labelledby="usersFilterDropdown" style={{ minWidth: '22rem' }}>

                            <div className="card">
                                <div className="card-header card-header-content-between">
                                    <h5 className="card-header-title">Filtrar por</h5>
                                    <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm ms-2">
                                        <i className="bi-x-lg"></i>
                                    </button>
                                </div>

                                <div className="card-body">
                                    {/* <div className="mb-3">
                                        <label className="form-label" >Ticket</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" >Número a 10 dígitos</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label" >Categorías </label>
                                        <Select
                                            isMulti
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Categorías'
                                        />
                                    </div> */}
                                    <div className='mb-3'>
                                        <label className="form-label" >Cuentas</label>
                                        <Select
                                            isMulti
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Cuentas'
                                        />
                                    </div>

                                    <hr />

                                    <div className="d-grid ">
                                        <button className="btn btn-primary">Aplicar</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        !props.hideExport && (
                            <div className="dropdown">
                                <button type="button" className="btn btn-white btn-sm dropdown-toggle w-100 w-100" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i className="bi-download me-1"></i> Exportar
                                </button>

                                <div className="dropdown-menu dropdown-menu-sm-end " >
                                    <button className="dropdown-item">
                                        <img className="avatar avatar-xss avatar-4x3 me-2" src="./assets/svg/brands/excel-icon.svg" alt="Image Description" />
                                        Excel
                                    </button>
                                    <button className="dropdown-item">
                                        <img className="avatar avatar-xss avatar-4x3 me-2" src="./assets/svg/components/placeholder-csv-format.svg" alt="Image Description" />
                                        .CSV
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    
                    <div className="dropdown">
                        <button type="button" className="btn btn-white btn-sm w-100" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <i className="bi-table me-1"></i> Columnas 
                        </button>

                        <div className="dropdown-menu dropdown-menu-sm-end dropdown-card card-dropdown-filter-centered" style={{ minWidth: '15rem' }}>

                            <div className="card">
                                <div className='card-body'>
                                    <div className='d-grid gap-3'>
                                        {
                                            props.columns.map( (c, index) => (
                                                <label className="row form-check form-switch" htmlFor={`toggleColumn_order_${index}`} key={index}>
                                                    <span className="col-8 col-sm-9 ms-0">
                                                        <span className="me-2">{c.name}</span> </span>
                                                    <span className="col-4 col-sm-3 text-end">
                                                        <input type="checkbox" className="form-check-input" id={`toggleColumn_order_${index}`} />
                                                    </span>
                                                </label>
                                            ))
                                        }
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className="table-responsive datatable-custom ">
                <DataTable
                    // defaultSortFieldId={1}
                    columns={props.columns}
                    data={props.data}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    responsive
                    sortIcon={<i className="custom-sort-icon"></i>}
                    noDataComponent='No hay registros para mostrar'
                    className='datatable-vendor'
                />

            </div>
        </div>
    )
}
export default CustomDataTable