import { useCallback, useEffect, useMemo, useRef, useState, MouseEvent as ReactMouseEvent } from "react";
import ReactFlow, { addEdge, Background, Controls, MarkerType, useEdgesState, useNodesState, Node, useReactFlow, ReactFlowInstance, applyNodeChanges, applyEdgeChanges, NodeChange, EdgeChange, Connection } from "reactflow";
import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import 'reactflow/dist/style.css';
import FormNode from "../../components/form-node/FormNode";
import NodeType from "../../components/form-node/NodeType";
import { BotService } from "../../services/BotService";
import TextUpdaterNode from "../../components/form-node/NodeType";

declare const bootstrap: any;

const rfStyle = {
    backgroundColor: '#B8CEFF',
};

const initialNodes = [
    { id: 'node-1', type: 'textUpdater', position: { x: 0, y: 0 }, data: { value: 123 } },
    {
        id: 'node-2',
        type: 'output',
        targetPosition: 'top',
        position: { x: 0, y: 200 },
        data: { label: 'node 2' },
    },
    {
        id: 'node-3',
        type: 'output',
        targetPosition: 'top',
        position: { x: 200, y: 200 },
        data: { label: 'node 3' },
    },
];

const initialEdges = [
    // { id: 'edge-1', source: 'node-1', target: 'node-2', sourceHandle: 'a',  zIndex: 10 },
    // { id: 'edge-2', source: 'node-1', target: 'node-3', sourceHandle: 'b' },
];

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { textUpdater: TextUpdaterNode };

function BotBuilderPage() {


    const [nodes, setNodes] = useState<any>(initialNodes);
    const [edges, setEdges] = useState<any>(initialEdges);

    const onNodesChange = useCallback(
        (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection: Connection) => {
            console.log('connection', connection)
            setEdges((eds) => {
                console.log('eds', eds)
                
                return addEdge({...connection, zIndex: 10}, eds)
            })
        },
        [setEdges]
    );

    return (
        
        <>
             <Header />
             <Aside />

             <main id="content" role="main" className="main">

                 <div className="content container-fluid" >

                     <div className="page-header">
                         <div className="row align-items-center">
                             <div className="col-sm mb-2 mb-sm-0">
                                 <nav aria-label="breadcrumb">
                                     <ol className="breadcrumb breadcrumb-no-gutter">
                                         <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
                                         <li className="breadcrumb-item active" aria-current="page">Chatbot</li>
                                     </ol>
                                 </nav>
                                 <h1 className="page-header-title"><i className="bi-robot nav-icon"></i> Chatbot</h1>
                             </div>
                         </div>

                     </div>


                     <div className="row text-center ">
                         <div className="col-sm-9">
                             <div className="card ">
                                 <div className="card-body " style={{ height: 650 }}>

                                    <ReactFlow
                                        nodes={nodes}
                                        edges={edges}
                                        onNodesChange={onNodesChange}
                                        onEdgesChange={onEdgesChange}
                                        onConnect={onConnect}
                                        nodeTypes={nodeTypes}
                                        fitView
                                        style={rfStyle}
                                    />
                                 </div>
                             </div>
                         </div>

                         <div className="col-sm-3 content-start">
                             <div className="card ">
                                 <div className="card-body d-flex align-items-center " style={{ height: 650 }}>
                                     <div className="alert alert-soft-secondary">
                                         <p className="h2"><i className="bi bi-robot"></i></p>
                                         <p>Da clic en cualquier bloque o elemento para ver su propiedad.</p>
                                     </div>
                                 </div>
                             </div>

                         </div>
                     </div>

                 </div>

                 <Footer />
             </main>
         </>
    );

    // const formElement = useRef(null)

    // const [nodeToConfig, setNodeToConfig] = useState({ parent: null, child: null })

    // const nodeTypes = useMemo(() => ({ cardNodeType: NodeType }), []);

    // const [nodes, setNodes, onNodesChange] = useNodesState([]);
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    // const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    // const openForm = (parentNode?: any, childNode?: any) => {
    //     const bsOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(formElement.current)
    //     bsOffcanvas.show()

    //     console.log({
    //         parent: parentNode,
    //         child: childNode
    //     })
    //     setNodeToConfig({
    //         parent: parentNode,
    //         child: childNode
    //     })
    // }

    // const handleChildNode = (node: any, option: string) => {

    //     setNodes((nds) => {

    //         const existsConnection = nds.find(n => {

    //             // console.log(n.data.keywords, option)
    //             return n.data.parent_nodes.indexOf(node._id) !== -1 && n.data.keywords.indexOf(option) !== -1
    //         });
    //         console.log('existsConnection', existsConnection)
    //         openForm(
    //             {
    //                 ...node,
    //                 keywords: [{ label: option, value: option, isFixed: true }]
    //             },
    //             existsConnection?.data
    //         );
    //         return nds
    //     })
    // }

    // const editNode = (node: any) => {
    //     node.keywords = node.keywords || []
    //     setNodes((nds) => {

    //         let existsParent = nds.find((n: any) => node.parent_nodes.indexOf(n.id) !== -1);

    //         let parent = null;
    //         if (existsParent) {
    //             parent = {
    //                 ...existsParent.data,
    //                 keywords: node.keywords.map((k: any) => ({ label: k, value: k }))
    //             }
    //         } else {
    //             parent = {
    //                 keywords: node.keywords.map((k: any) => ({ label: k, value: k }))
    //             }
    //         }


    //         openForm(
    //             parent,
    //             node
    //         );
    //         return nds
    //     })


    //     // openForm(
    //     //     {
    //     //         ...node,
    //     //         keyword: [ {label: option, value: option, isFixed: true} ]
    //     //     },
    //     //     node
    //     // );
    // }

    // const getTree = async () => {
    //     const response = await BotService.getTree(101)

    //     let treeResponse = response.data;

    //     let initialNodes$: any = []
    //     let initialEdges$: any = [];

    //     let nodeKeys = Object.keys(treeResponse)

    //     for (const key of nodeKeys) {
    //         initialNodes$.push({
    //             id: treeResponse[key]._id,
    //             type: 'cardNodeType',
    //             data: { ...treeResponse[key], onClick: (option) => handleChildNode(treeResponse[key], option), editNode: () => editNode(treeResponse[key]) },
    //             // data: {...treeResponse[key], onClick: (option: string) => handleChildNode(treeResponse[key], option)},
    //             position: treeResponse[key].position,
    //         })

    //         const connections = treeResponse[key].outputs.map((output: any) => ({
    //             id: `${treeResponse[key]._id}-${output}`,
    //             source: treeResponse[key]._id,
    //             target: output,
    //             label: treeResponse[output].keywords.join(', '),
    //             markerEnd: {
    //                 type: MarkerType.Arrow,
    //                 width: 50,
    //                 height: 50,
    //                 color: 'red',
    //             }
    //         }))

    //         initialEdges$ = [...initialEdges$, ...connections];
    //     }

    //     // console.log(initialNodes$)
    //     // console.log(initialEdges$)
    //     setNodes(initialNodes$)
    //     setEdges(initialEdges$)
    // }

    // const handleDataNode = async (data: any) => {
    //     data.bot_id = 101;
    //     // console.log(data)
    //     await BotService.saveNode(data)

    //     const bsOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(formElement.current)
    //     bsOffcanvas.hide()

    //     getTree()
    // }

    // const updateCoordinates = async (event: ReactMouseEvent, node: Node) => {
    //     if (node.dragging) {
    //         await BotService.updateCoordinates({ _id: node.id, x: node.position.x, y: node.position.y })
    //     }
    // }

    // useEffect(() => {
    //     getTree()
    // }, [])

    // return (
    //     <>
    //         <Header />
    //         <Aside />

    //         <main id="content" role="main" className="main">

    //             <div className="content container-fluid" >

    //                 <div className="page-header">
    //                     <div className="row align-items-center">
    //                         <div className="col-sm mb-2 mb-sm-0">
    //                             <nav aria-label="breadcrumb">
    //                                 <ol className="breadcrumb breadcrumb-no-gutter">
    //                                     <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
    //                                     <li className="breadcrumb-item active" aria-current="page">Chatbot</li>
    //                                 </ol>
    //                             </nav>
    //                             <h1 className="page-header-title"><i className="bi-robot nav-icon"></i> Chatbot</h1>
    //                         </div>
    //                         <div className="col-auto">
    //                             <button className="btn btn-primary" onClick={() => openForm()}>Open Form</button>
    //                         </div>
    //                     </div>

    //                 </div>


    //                 <div className="row text-center ">
    //                     <div className="col-sm-9">
    //                         <div className="card ">
    //                             <div className="card-body " style={{ height: 650 }}>

    //                                 <ReactFlow
    //                                     nodes={nodes}
    //                                     edges={edges}
    //                                     nodeTypes={nodeTypes}
    //                                     onNodesChange={onNodesChange}
    //                                     onNodeDragStop={updateCoordinates}
    //                                     onEdgesChange={onEdgesChange}
    //                                     onConnect={onConnect}
    //                                 // onNodeClick={ (event: React.MouseEvent, node: Node) => console.log(node) }
    //                                 // onNodeMouseMove={(e) => console.log(e)}
    //                                 // fitView
    //                                 >
    //                                     <Controls />
    //                                     <Background />
    //                                 </ReactFlow>
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div className="col-sm-3 content-start">
    //                         <div className="card ">
    //                             <div className="card-body d-flex align-items-center " style={{ height: 650 }}>
    //                                 <div className="alert alert-soft-secondary">
    //                                     <p className="h2"><i className="bi bi-robot"></i></p>
    //                                     <p>Da clic en cualquier bloque o elemento para ver su propiedad.</p>
    //                                 </div>
    //                             </div>
    //                         </div>

    //                     </div>
    //                 </div>

    //             </div>

    //             <Footer />
    //         </main>

    //         <div className="offcanvas offcanvas-end" data-bs-backdrop="static" ref={formElement}>
    //             <FormNode nodeToConfig={nodeToConfig} onSave={handleDataNode} />
    //         </div>
    //     </>
    // );
}

export default BotBuilderPage;