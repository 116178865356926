
interface ButtonProps {
    children: any;
    type?: "button" | "submit" | "reset" | undefined;
    className?: string; 
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: any
    id?: string
}
function Button({ children,  type = 'button', className='', disabled = false, isLoading = false, onClick, id = '' }: ButtonProps) {

    const noop = () => {}

    return (
        <button type={type} className={className} disabled={disabled || isLoading} onClick={onClick || noop} id={id}>
            { isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> } {children}
        </button>
    )
}

export default Button