import { ReactNode } from "react";

interface IfThenProps extends React.PropsWithChildren {
    condition: any;
}

function IfThen({ condition, children }: IfThenProps): any {
  return condition ? children : undefined
}

export default IfThen