import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/form-controls/Button";
import Input from "../../components/form-controls/Input";
import Select from 'react-select'

declare const bootstrap: any;

interface ModalDBAddProps {
    show: boolean;
    onRequestClose: () => void
}
function ModalDBAdd({ show, onRequestClose }: ModalDBAddProps) {

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const defaultValues = {
        name: '',
        phone: '',
        account: '',
        subaccount: '',

        file: '',
        phone_column: '',
        name_column: '',
        id_column: '',

    }
    const { register, handleSubmit, formState: { errors, isValid, isSubmitting, submitCount } } = useForm({
        mode: 'onChange',
        defaultValues
    });


    const submit = async (data: typeof defaultValues) => {

    }

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }
    }, [modalRef])

    useEffect(() => {
        if (modal) {
            show ? modal.show() : modal.hide()
        }
    }, [show])

    return (
        <div className="modal fade" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog " role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" >Agregar base de clientes</h4>
                            <button type="button" className="btn-close" onClick={onRequestClose}></button>
                        </div>
                        <div className="modal-body">

                            <div className="row mb-5">
                                <div className="col-4">
                                    <h5>Información general</h5>
                                    <p className="small">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                                </div>
                                <div className="col-8">
                                    <div className="mb-3">
                                        <Input label='Nombre de la base *' errors={errors.name} {...register('name', { required: true })} />
                                    </div>
                                    {/* <div className="mb-3">
                                        <Input label='Nombre de la base *' errors={errors.name} {...register('name', { required: true })} />
                                    </div> */}
                                    <div className='mb-3'>
                                        <label className="form-label" >Cuenta *</label>
                                        <Select
                                            isMulti={false}
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Cuenta'
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label" >Subcuenta *</label>
                                        <Select
                                            isMulti={false}
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Subcuenta'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <h5>Datos de la base</h5>
                                    <p className="small">Adjunta el archico .csv con la información de tus clientes y define las columnas que corresponden al número, nombre y folio</p>
                                </div>
                                <div className="col-8">
                                    <div className="mb-3">
                                        <Input type='file' label='Archivo *' errors={errors.file} {...register('file', { required: true })} />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label" >Número *</label>
                                        <Select
                                            isMulti={false}
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Número'
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label" >Nombre *</label>
                                        <Select
                                            isMulti={false}
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Número'
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="form-label" >Folio *</label>
                                        <Select
                                            isMulti={false}
                                            name="colors"
                                            options={[
                                                { value: 'chocolate', label: 'Chocolate' },
                                                { value: 'strawberry', label: 'Strawberry' },
                                                { value: 'vanilla', label: 'Vanilla' }
                                            ]}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            placeholder='Número'
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white btn-sm" onClick={onRequestClose}>Cancelar</button>
                            <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ModalDBAdd