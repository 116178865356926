import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function TextUpdaterNode(props) {
    
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <div className="text-updater-node" style={{ backgroundColor: 'white', padding: '8px'}}>
      <Handle type="target" position={Position.Top} />
      <div >
        <p>Aqui van cosas</p>
        <ul style={{ }}>
            <li style={{ background: 'blue', position: 'relative'}}>
                <div>
                    <p>Opción 1</p>
                    
                </div>
                <Handle type="source" position={Position.Bottom} id="a" style={{ backgroundColor: 'red'}} />
            </li>
            <li style={{ background: 'blue', position: 'relative'}}>
                <div>
                    <p>Opción 2</p>
                    
                </div>
                <Handle type="source" position={Position.Bottom} id="b" style={{ backgroundColor: 'red'}} />
            </li>
            <li style={{ background: 'blue', position: 'relative'}}>
                <div>
                    <p>Opción 3</p>
                    
                </div>
                <Handle type="source" position={Position.Bottom} id="c" style={{ backgroundColor: 'red'}} />
            </li>
        </ul>
      </div>
      {/* <Handle type="source" position={Position.Bottom} id="a" style={handleStyle} /> */}
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default TextUpdaterNode;
