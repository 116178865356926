let env: any  = process.env.REACT_APP_STAGE;


let _API_  = {
    CORE: 'https://desarrollo.ws-voices.com.mx/',
    BOT_V2: 'https://bot.ws-voices.com.mx:3116/',
    BOT_V3: 'http://localhost:8080/',
}


switch (env) {
    case 'dev':
        _API_  = {
            CORE: 'https://desarrollo.ws-voices.com.mx/',
            BOT_V2: 'https://bot.ws-voices.com.mx:3116/',
            BOT_V3: 'http://localhost:8080/',
        }
        break;
    case 'prod':
        _API_  = {
            CORE: 'https://ws-api.ws-voices.com.mx/',
            BOT_V2: 'https://bot.ws-voices.com.mx/',
            BOT_V3: 'http://localhost:8080/',
        }
        break;

    default:
        break;
}

export const API_ENDPOINT = _API_;

export const STORAGE = {
    TOKEN: 'voices-wa-token',
    SESSION: 'voices-wa-session',
}