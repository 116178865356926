import { createRef, useState } from "react";
import Aside from "../../components/Aside";
import CustomDataTable from "../../components/CustomDataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ModalUser from "./ModalUser";

export default function UsersPage() {

    const [showModal, setShowModal] = useState(false)

    const columns = [
        {
            name: 'ID',
            sortable: true,
            reorder: true,
            grow: 0,
            compact: true,
            selector: row => row.id,
        },
        {
            name: 'Nombre de usuario',
            sortable: true,
            reorder: true,
            grow: 2,
            selector: row => row.username,
        },
        {
            name: 'Nombre de completo',
            sortable: true,
            reorder: true,
            grow: 2,
            selector: row => row.fullname,
        },
        {
            name: 'Cuenta',
            sortable: true,
            reorder: true,
            selector: row => row.account,
        },
        {
            name: 'Subcuenta',
            sortable: true,
            reorder: true,
            selector: row => row.account,
        },
        {
            name: 'Perfil',
            sortable: true,
            reorder: true,
            selector: row => row.type,
        },
        {
            name: 'Estatus',
            sortable: true,
            reorder: true,
            selector: row => row.status,
            cell: (row, index, column, id) => {

                return (
                    <span className="badge bg-soft-info text-info">
                        <span className="legend-indicator bg-info"></span> Activo
                    </span>
                )
            }
        },
        {
            name: 'Acciones',
            sortable: false,
            reorder: false,
            center: true,
            grow: 2,
            cell: (row, index, column, id) => {
                return (
                    <div className="btn-group" role="group">
                        <button className="btn btn-white btn-sm" onClick={showFormUser}>
                            <i className="bi-pencil-fill me-1"></i> Editar
                        </button>

                        <div className="btn-group">
                            <button type="button" className="btn btn-white btn-icon btn-sm dropdown-toggle dropdown-toggle-empty"  data-bs-toggle="dropdown" aria-expanded="false"></button>

                            <div className="dropdown-menu dropdown-menu-end mt-1" >
                                <button className="dropdown-item">
                                    Reestablecer contraseña
                                </button>
                                <button className="dropdown-item">
                                    Cambiar subcuenta
                                </button>
                                <button className="dropdown-item">
                                    Desactivar
                                </button>
                                <button className="dropdown-item">
                                    Borrar
                                </button>
                            </div>
                        </div>

                    </div>
                )
            }
        },
    ];

    const data = [
        {
            id: 1,
            username: 'admin',
            fullname: 'Luis Zamudio',
            account: 'voices',
            subaccount: 'voices',
            type: 'Administrador',
            status: true,
        },
    ]


    const showFormUser = () => {
        setShowModal(true)
    }


    return (
        <>
            <Header />
            <Aside />

            <main id="content" role="main" className="main">

                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Usuarios</li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title"><i className="bi-people"></i> Usuarios</h1>
                            </div>
                            <div className="col-sm-auto">
                                <button className="btn btn-primary" onClick={showFormUser}><i className="bi-plus-lg me-1"></i> Agregar usuario</button>
                            </div>
                        </div>
                    </div>

                    <CustomDataTable
                        columns={columns}
                        data={data}
                    />

                </div>
                <Footer />
            </main>

            <ModalUser show={showModal} onRequestClose={() => setShowModal(false)} />

        </>
    )
}