import React from "react";

export default React.forwardRef(( { label, errors, ...register }: any, ref) => (
    <>
        <label className="form-label" >{label}</label>
        <textarea ref={ref} className={'form-control form-control-lg ' + (errors ? 'is-invalid' : '')} {...register} rows={5}/>
        <span className="invalid-feedback">
                { errors?.type === 'custom' && `${errors?.message }`}
                { errors?.type !== 'custom' && 'Campo obligatorio.' }
            </span>
    </>
))