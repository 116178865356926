import IfThen from "./IfThen";

interface FooterProps {
    showAlertDummy?: boolean;
}
function Footer({showAlertDummy}: FooterProps) {
    return (
        <div className="footer">
            <IfThen condition={showAlertDummy}>
                <div className="position-fixed start-50 bottom-0 translate-middle-x w-50 zi-99 mb-3 bg-white">
                    <div className="card card-sm  alert alert-soft-danger mx-2 ">
                        <div className="card-body mx-auto">
                            <div className="" role="alert">
                                <div className="d-flex">
                                    <i className="bi bi-exclamation-triangle-fill" style={{ fontSize: '32px'}}></i>
                                    <div className="ms-3">
                                        La cuenta de WhatsApp con número +52 55 4433 2211 se encuentra sin conexión, contacta a tu administrador para más información
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IfThen>
            <div className="row justify-content-between align-items-center">
                <div className="col">
                    <p className="fs-6 mb-0">HelpDesk <span className="d-none d-sm-inline-block">2022 Voices Comunicaciones Unificadas.</span></p>
                </div>


                <div className="col-auto">
                    <div className="d-flex justify-content-end">

                        <ul className="list-inline list-separator">
                            <li className="list-inline-item">

                                <button className="btn btn-ghost-secondary btn btn-icon btn-ghost-secondary rounded-circle" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasKeyboardShortcuts" aria-controls="offcanvasKeyboardShortcuts">
                                    <i className="bi-command"></i>
                                </button>

                            </li>
                        </ul>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Footer