import axios from 'axios';
import { STORAGE } from '../constants';
import { LocalStorageService } from './LocalStorageService';



const _HTTP_ = axios.create();

_HTTP_.interceptors.request.use(
    (config: any) => {
        const token = LocalStorageService.get(STORAGE.TOKEN);
        if(token) {
            config.headers['token-api'] = `${token}`;
        }

        return config;
    }
)

_HTTP_.interceptors.response.use(
    (response) => {
        if(response.data.code && +response.data.code !== 200 ) {
            return handleResponseCode(+response.data.code, response.data.desc)
        }

        return response.data
    },
    (error) => {
        console.log('HTTP::interceptor::error', error)
        const { status } = error.response;

        return handleResponseCode(status, error.response.data)
    }
)

const handleResponseCode = (code: number, errorMessage: any) => {

    switch (code) {
        case 500:
        case 400:
            return Promise.reject(errorMessage || { message: 'Unknown error' });
        case 401:
            window.location.replace('/auth/login');
            LocalStorageService.clear();
            break;
    }
    
}

export const HTTP = _HTTP_;
