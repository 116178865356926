import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function NotFoundPage() {
  return (
    <>
      <Header />
      <Aside />

      <main id="content" role="main" className="main">

        <div className="content container-fluid">

          <div className="row justify-content-center align-items-sm-center py-sm-10">
            <div className="col-9 col-sm-6 col-lg-4">
              <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
                <img className="img-fluid" src="/assets/svg/illustrations/oc-thinking.svg" alt="404 Not Found" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 text-center text-sm-start">
              <h1 className="display-1 mb-0">404</h1>
              <p className="lead">Lo sentimos, la página que estás buscando no se puede encontrar.</p>
            </div>
          </div>

        </div>
        <Footer />
      </main>
    </>
  )
}