import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { STORAGE } from './constants';
import './index.scss';
import LoginPage from './pages/auth/LoginPage';
import ResetPasswordPage from './pages/auth/ResetPassword';
import BdClientsPage from './pages/bd-clients/BDClientsPage';
import BotBuilderPage from './pages/bot/BotBuilder';
import ChannelsPage from './pages/channels/ChannelsPage';
import ConfigurationPage from './pages/configuration/ConfigurationPage';
import NotFoundPage from './pages/error/NotFoundPage';
import PaymentPage from './pages/payments/PaymentPage';
import UsersPage from './pages/users/UsersPage';
import { LocalStorageService } from './services/LocalStorageService';

declare const HSSideNav: any;

const PrivateRoute = () => {

  useEffect(() => {
    document.body.className = 'footer-offset has-navbar-vertical-aside navbar-vertical-aside-show-xl';
    
    return () => {
        document.body.className = '';
    };
}, [])

  const session = LocalStorageService.get(STORAGE.SESSION);

  return !session ? <Navigate to='/auth/login' replace /> : <Outlet />;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
    <BrowserRouter>
      <Routes >
        <Route path="/auth" >
          <Route path="login" element={<LoginPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<NotFoundPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/bd-clients" element={<BdClientsPage />} />
          <Route path="/payments" element={<PaymentPage />} />
          <Route path="/channels" element={<ChannelsPage />} />
          <Route path="/configuration" element={<ConfigurationPage />} />
          <Route path="/bot">
            <Route path="builder" element={<BotBuilderPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>

);
