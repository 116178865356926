import { createRef, useState } from "react";
import Aside from "../../components/Aside";
import CustomDataTable from "../../components/CustomDataTable";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ModalDBAdd from "./ModalDBAdd";;

export default function BdClientsPage() {

    const [showModal, setShowModal] = useState(false)

    const columns = [
        {
            name: 'Nombre',
            sortable: true,
            reorder: true,
            selector: row => row.name,
        },
        {
            name: 'Cuenta',
            sortable: true,
            reorder: true,
            selector: row => row.account,
        },
        {
            name: 'Subcuenta',
            sortable: true,
            reorder: true,
            selector: row => row.subaccount,
        },
        {
            name: 'Fecha',
            sortable: true,
            reorder: true,
            selector: row => row.date,
        },
        {
            name: 'Progreso',
            sortable: true,
            reorder: true,
            grow: 1,
            selector: row => row.status,
            cell: (row, index, column, id) => {

                return (
                    <div className="d-flex align-items-center">
                        <span className="mb-0 me-2">26%</span>
                        <div className="progress table-progress">
                            <div className="progress-bar" style={{ width: '26%'}}></div>
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Acciones',
            sortable: false,
            reorder: false,
            center: true,
            grow: 2,
            cell: (row, index, column, id) => {
                return (
                    <div className="btn-group" role="group">
                        <button className="btn btn-white btn-sm" onClick={showFormUser}>
                            Reprocesar
                        </button>

                        <div className="btn-group">
                            <button type="button" className="btn btn-white btn-icon btn-sm dropdown-toggle dropdown-toggle-empty"  data-bs-toggle="dropdown" aria-expanded="false"></button>

                            <div className="dropdown-menu dropdown-menu-end mt-1" >
                                <button className="dropdown-item">
                                    Descargar
                                </button>
                                <button className="dropdown-item">
                                    Desactivar
                                </button>
                            </div>
                        </div>

                    </div>
                )
            }
        },
    ];

    const data = [
        {
            id: 1,
            name: 'Nombre de base',
            account: 'voices',
            subaccount: 'voices',
            date: '06/12/2023 15:34',
            status: true,
        },
    ]


    const showFormUser = () => {
        setShowModal(true)
    }


    return (
        <>
            <Header />
            <Aside />

            <main id="content" role="main" className="main">

                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item "><span className="breadcrumb-link" >Operación</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Base de clientes</li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title"><i className="bi-person-bounding-box nav-icon"></i> Base de clientes</h1>
                            </div>
                            <div className="col-sm-auto">
                                <button className="btn btn-primary" onClick={showFormUser}><i className="bi-plus-lg me-1"></i> Agregar base de clientes</button>
                            </div>
                        </div>
                    </div>

                    <CustomDataTable
                        columns={columns}
                        data={data}
                        showDates
                        hideExport
                    />

                </div>
                <Footer />
            </main>

            <ModalDBAdd show={showModal} onRequestClose={() => setShowModal(false)} />

        </>
    )
}