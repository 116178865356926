import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/form-controls/Button";
import Input from "../../components/form-controls/Input";
import Select from 'react-select'

declare const bootstrap: any;

interface ModalUserProps {
    show: boolean;
    onRequestClose: () => void
}
function ModalUser({ show, onRequestClose }: ModalUserProps) {

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const defaultValues = {
        username: '',
        email: '',
        sendToMyEmail: false,
        fullname: '',
        type: '',
        accounts: '',
    }
    const { register, handleSubmit, formState: { errors, isValid, isSubmitting, submitCount } } = useForm({
        mode: 'onChange',
        defaultValues
    });


    const submit = async (data: typeof defaultValues) => {

    }

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }
    }, [modalRef])

    useEffect(() => {
        if(modal) {
            show ? modal.show(): modal.hide()
        }
    }, [show])

    return (
        <div className="modal fade" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" >Agregar usuario</h4>
                            <button type="button" className="btn-close" onClick={onRequestClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row ">
                                <div className="mb-3">
                                    <Input label='Nombre de usuario *' errors={errors.username} {...register('username', { required: true })} />
                                </div>
                                <div className="mb-3">
                                    <div className="form-check form-switch ">
                                        <label className="form-check-label" htmlFor="is_active">Enviar accesos a mi correo</label>
                                        <input type="checkbox" className="form-check-input form-check-info" id="sendToMyEmail" {...register('sendToMyEmail')} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Input label='Correo electrónico *' errors={errors.email} {...register('email', { required: true })} />
                                </div>
                                <div className="mb-3">
                                    <Input label='Nombre de completo *' errors={errors.fullname} {...register('fullname', { required: true })} />
                                </div>
                                <div className="mb-3">
                                    <Input label='Perfil *' errors={errors.type} {...register('type', { required: true })} />
                                </div>

                                <div className='mb-3'>
                                    <label className="form-label" >Cuenta *</label>
                                    <Select
                                        isMulti={false}
                                        name="colors"
                                        options={[
                                            { value: 'chocolate', label: 'Chocolate' },
                                            { value: 'strawberry', label: 'Strawberry' },
                                            { value: 'vanilla', label: 'Vanilla' }
                                        ]}
                                        className={false ? 'form-select-custom is-invalid' : ''}
                                        classNamePrefix='form-select-custom'
                                        placeholder='Cuenta'
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white btn-sm" onClick={onRequestClose}>Cancelar</button>
                            <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ModalUser