import { useEffect, useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom"
import { STORAGE } from "../constants";
import { LocalStorageService } from "../services/LocalStorageService";


function Header() {
    
    const navigate = useNavigate();
    
    const session = useMemo(() => LocalStorageService.get(STORAGE.SESSION), [])
    
    const logout = () => {
        navigate('/auth/login')
    }    

    return (
        <header id="header" className="navbar navbar-expand-lg navbar-fixed navbar-container navbar-bordered bg-white">
            <div className="navbar-nav-wrap">

                <a className="navbar-brand" href="../index.html" aria-label="Front">
                    <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Logo" data-hs-theme-appearance="default" />
                    <img className="navbar-brand-logo-mini" src="../assets/svg/logos/logo-short.svg" alt="Logo" data-hs-theme-appearance="default" />
                </a>
                <div className="navbar-nav-wrap-content-start">

                    <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                        <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                        <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                    </button>

                    {/* <div className="alert alert-soft-danger m-0" role="alert">
                        <div className="d-flex">
                            <i className="bi bi-exclamation-triangle-fill"></i>
                            <div className="ms-2">
                            Cuenta sin conexión, contacta a tu administrador para más información
                            </div>
                        </div>
                    </div> */}
                </div>


                <div className="navbar-nav-wrap-content-end">

                    <ul className="navbar-nav">
                        <li className="nav-item d-none d-sm-inline-block">

                            <div className="dropdown">
                                <button type="button" className="btn btn-ghost-secondary btn-icon rounded-circle" id="navbarNotificationsDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                                    <i className="bi-bell"></i>
                                    <span className="btn-status btn-sm-status btn-status-danger"></span>
                                </button>

                                <div className="dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless" aria-labelledby="navbarNotificationsDropdown" style={{ width: '25rem' }}>
                                    <div className="card">

                                        <div className="card-header card-header-content-between">
                                            <h4 className="card-title mb-0">Notifications</h4>
                                        </div>


                                        <div className="card-body-height">

                                            <div className="tab-content" id="notificationTabContent">
                                                <div className="tab-pane fade show active" id="notificationNavOne" role="tabpanel" aria-labelledby="notificationNavOne-tab">

                                                    <ul className="list-group list-group-flush navbar-card-list-group">

                                                        <li className="list-group-item form-check-select">
                                                            <div className="row">

                                                                <div className="col ms-n2">
                                                                    <h5 className="mb-1">Brian Warner</h5>
                                                                    <p className="text-body fs-5">changed an issue from "In Progress" to <span className="badge bg-success">Review</span></p>
                                                                </div>
                                                                <small className="col-auto text-muted text-cap">2hr</small>
                                                            </div>

                                                            <a className="stretched-link" href="#"></a>
                                                        </li>
                                                        <li className="list-group-item form-check-select">
                                                            <div className="row">

                                                                <div className="col ms-n2">
                                                                    <h5 className="mb-1">Brian Warner</h5>
                                                                    <p className="text-body fs-5">changed an issue from "In Progress" to <span className="badge bg-success">Review</span></p>
                                                                </div>


                                                                <small className="col-auto text-muted text-cap">2hr</small>

                                                            </div>


                                                            <a className="stretched-link" href="#"></a>
                                                        </li>

                                                    </ul>

                                                </div>

                                                <div className="tab-pane fade" id="notificationNavTwo" role="tabpanel" aria-labelledby="notificationNavTwo-tab">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </li>

                        <li className='nav-item d-none d-sm-inline-block'>
                            <div className="dropdown">
                                    
                                    <button type="button" className="btn btn-ghost-secondary btn-icon rounded-circle" id="selectLanguageDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                                        <img className="avatar avatar-xss avatar-circle" src="../assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="United States Flag" />
                                    </button>

                                    <div className="dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless" aria-labelledby="selectLanguageDropdown">
                                        <span className="dropdown-header">Select language</span>
                                        <a className="dropdown-item" href="#">
                                            <img className="avatar avatar-xss avatar-circle me-2" src="../assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="Flag" />
                                            <span className="text-truncate" title="English">English (US)</span>
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <img className="avatar avatar-xss avatar-circle me-2" src="../assets/vendor/flag-icon-css/flags/1x1/mx.svg" alt="Flag" />
                                            <span className="text-truncate" title="English">Español</span>
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <img className="avatar avatar-xss avatar-circle me-2" src="../assets/vendor/flag-icon-css/flags/1x1/cn.svg" alt="Flag" />
                                            <span className="text-truncate" title="中文 (繁體)">中文 (繁體)</span>
                                        </a>
                                    </div>
                                </div>
                        </li>

                        <li className="nav-item">

                            <div className="dropdown">
                                <a className="navbar-dropdown-account-wrapper" href="#" id="accountNavbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>
                                    <span className="avatar avatar-sm avatar-primary avatar-circle">
                                        <span className="avatar-initials">{(session.name || session.user_name)[0]}</span>
                                    </span>
                                </a>

                                <div className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" style={{ width: '16rem' }}>
                                    <div className="dropdown-item-text">
                                        <div className="d-flex align-items-center">
                                            <span className="avatar avatar-sm avatar-primary avatar-circle">
                                                <span className="avatar-initials">{(session.name || session.user_name)[0]}</span>
                                            </span>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="mb-0">{session.name || session.user_name}</h5>
                                                <p className="card-text text-body">{session.email || session.profile}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" onClick={logout}>Cerrar sesión</button>
                                </div>
                            </div>

                        </li>
                    </ul>

                </div>

            </div>
        </header>
    )
}

export default Header