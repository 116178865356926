import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function ChannelsPage() {
    return (
        <>
            <Header />
            <Aside />

            <main id="content" role="main" className="main">

                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Canales</li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title"><i className="bi-collection nav-icon"></i> Canales</h1>
                            </div>


                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-4 mb-3 mb-lg-0">
                            <h4>Canales de comunicación</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit consequatur exercitationem tenetur adipisci. </p>
                        </div>
                        <div className="col-lg-8">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className="list-group list-group-lg list-group-flush list-group-no-gutters">

                                                <div className="list-group-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img className="avatar avatar-xs" src="/assets/svg/brands/whatsapp-icon.png" alt="Image Description" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="d-flex">
                                                                        <h4 className="mb-0 me-2">WhatsApp </h4>
                                                                        <span className="badge bg-soft-danger  text-danger "><span className="legend-indicator bg-danger"></span>Cuenta sin conexión</span>
                                                                    </div>
                                                                    
                                                                    <p className="fs-5 text-body mb-0">+52 55 4433 2211</p>
                                                                    {/* <div className="mt-2">
                                                                        <span className="badge bg-soft-danger  text-danger "><span className="legend-indicator bg-danger"></span>Cuenta sin conexión</span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="d-flex align-items-center">
                                                                        <button type="button" className="btn btn-white btn-sm "><i className="bi bi-gear-fill"></i> Configurar</button>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img className="avatar avatar-xs" src="/assets/svg/brands/whatsapp-business-icon.png" alt="Image Description" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="d-flex">
                                                                        <h4 className="mb-0 me-2">WhatsApp Business </h4>
                                                                        <span className="badge bg-soft-info  text-info  "><span className="legend-indicator bg-info"></span>En línea</span>
                                                                    </div>
                                                                    
                                                                    <p className="fs-5 text-body mb-0">+52 55 4433 2211</p>
                                                                    {/* <div className="mt-2">
                                                                        <span className="badge bg-soft-primary  text-primary  "><span className="legend-indicator bg-success"></span>Cuenta en línea</span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="d-flex align-items-center">
                                                                        <button type="button" className="btn btn-white btn-sm "><i className="bi bi-gear-fill"></i> Configurar</button>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img className="avatar avatar-xs" src="/assets/svg/brands/rcs-icon.png" alt="Image Description" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <h4 className="mb-0">RCS </h4>
                                                                    {/* <div className="mt-2">
                                                                        <span className="badge bg-soft-primary  text-primary">Método de pago activo</span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="badge bg-soft-danger  text-danger  p-2">Desactivado</span>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img className="avatar avatar-xs" src="/assets/svg/brands/calls-icon.png" alt="Image Description" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <h4 className="mb-0">Llamadas </h4>
                                                                    {/* <div className="mt-2">
                                                                        <span className="badge bg-soft-danger  text-danger">Canal inactivo</span>
                                                                    </div> */}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="d-flex align-items-center">
                                                                    <span className="badge bg-soft-danger  text-danger  p-2">Desactivado</span>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                               

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-danger text-danger">Requiere configuración</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/openpay-logo.png' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Configurar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-danger text-danger">Método de pago inactivo</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/paypal-logo.png' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Administrar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body ">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><span className="badge bg-soft-primary  text-primary">Método de pago predeterminado</span></li>
                                                </ul>
                                                <h3 className="my-3">
                                                    <img src='https://v3.ws-voices.com.mx/assets/img/logo_conekta_color.svg' style={{width: '100%'}} />
                                                </h3>

                                                <div className="text-start ">
                                                    <button type="button" className="btn btn-link">Administrar</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                
                                </div> */}
                        </div>
                    </div>

                </div>
                <Footer  showAlertDummy/>
            </main>


        </>
    )
}