
import { API_ENDPOINT } from "../constants";
import { HTTP } from "./http";

export const AuthService = {

    login: (data: {
        user_name: string,
        password: string,
    }) => {
        return HTTP.post<any, any>(`${API_ENDPOINT.CORE}auth/login`, data);
    },
    
    forgotPassword: (data: {
        user_name: string
    }) => {
        return HTTP.post<any, any>(`${API_ENDPOINT.CORE}auth/rememberPass`, data);
    },
}