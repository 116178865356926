import Aside from "../../components/Aside";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function ConfigurationPage() {
    return (
        <>
            <Header />
            <Aside />

            <main id="content" role="main" className="main">

                <div className="content container-fluid">

                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm mb-2 mb-sm-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-no-gutter">
                                        <li className="breadcrumb-item "><span className="breadcrumb-link" >Plataforma</span></li>
                                        <li className="breadcrumb-item active" aria-current="page">Configuración</li>
                                    </ol>
                                </nav>
                                <h1 className="page-header-title"><i className="bi-gear nav-icon"></i> Configuración</h1>
                            </div>


                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3">
                            <div className="navbar-expand-lg navbar-vertical mb-3 mb-lg-5"  >

                                <div className="d-grid">
                                    <button type="button" className="navbar-toggler btn btn-white mb-3" data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenuCardTabs" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenuCardTabs">
                                        <span className="d-flex justify-content-between align-items-center">
                                            <span className="text-dark">Menu</span>

                                            <span className="navbar-toggler-default">
                                                <i className="bi-list"></i>
                                            </span>

                                            <span className="navbar-toggler-toggled">
                                                <i className="bi-x"></i>
                                            </span>
                                        </span>
                                    </button>
                                </div>



                                <div id="navbarVerticalNavMenuCardTabs" className="collapse navbar-collapse">
                                    <div id="navbarSettingsCardWithNavTab" className="card card-navbar-nav nav nav-tabs nav-vertical">

                                        <button className="nav-link py-3 active" >
                                             Subcuentas
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Formularios de cierre 
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Tickets y asignación
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Plantillas 
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Alertas
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Estatus de agente
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Horarios de atención
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Mensajes automáticos
                                        </button>
                                        <button className="nav-link py-3 " >
                                             Permisos de agente
                                        </button>

                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <div className="card">

                                        <div className="card-body">
                                            

                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
                <Footer  />
            </main>


        </>
    )
}