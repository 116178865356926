import React from "react";

export default React.forwardRef(( { type= 'text', label, errors, children, ...register }: any, ref) => {

    return  (
        <>
            <label className="form-label" >{label}</label>
            <div className={'d-flex ' + (errors ? 'is-invalid' : '')}>
                <input type={type} ref={ref} className={'form-control form-control-lg ' + (errors ? 'is-invalid' : '')} {...register} />
                {children}
            </div>
            
            <span className="invalid-feedback">
                { errors?.type === 'custom' && `${errors?.message }`}
                { errors?.type !== 'custom' && 'Campo obligatorio.' }
            </span>
        </>
    )
}
)